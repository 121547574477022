<template>
  <div class="lesson" v-loading="pageLoading">
    <el-dialog
      title="扫码支付"
      :visible.sync="dialogVisible"
      custom-class="payDialog"
      width="279px"
    >
      <div class="toPay">
        <!-- <span>{{buyTime}}s后关闭</span> -->
        <span>暂时只支持「微信」扫码</span>
        <vue-qr :text="payUrl" width="200" height="200" :margin="0"></vue-qr>
        <!-- <p @click="closeBuy">已完成付款</p> -->
      </div>
    </el-dialog>
    <div class="title">订单详情</div>
    <div class="state bg">
       <p class="stated" v-if="base_info.status == 4"><img :src="finished"/>已完成</p>
       <p v-if="base_info.status == 4">感谢您选择婴幼高研院-为您提供优质的课程资源</p>

       <p class="stated" v-if="base_info.status == 1">待支付</p>
       <p v-if="base_info.status == 1">剩余：<van-count-down :time="time" @finish="getData" v-if="time > 0"/></p>
       <p v-if="base_info.status == 1" class="btn" @click="toPay">立即支付</p>

       <p class="stated" v-if="base_info.status == 5">已取消</p>
       <p v-if="base_info.status == 5">期待您下次体验</p>
    </div>
    <h5>商品信息</h5>
    <div class="bg productInfo">
      <img :src="goods_info.cover_mobile || $placeImg"/>
      <div><p>{{ goods_info.name }}</p>有效期：1年</div>
      ￥{{ goods_info.price }}
    </div>
    <h5>订单信息</h5>
    <div class="bg orderInfo">
      <p><span>合计：</span>{{ base_info.amount }}</p>
      <p><span>优惠抵减：</span>{{ (base_info.amount_should-base_info.amount).toFixed(2) }}</p>
      <p><span>订单编号：</span>{{ base_info.orderno }}</p>
      <p><span>提交时间：</span>{{ base_info.created_at }}</p>
      <p  v-if="base_info.status == 4"><span>支付方式：</span>{{ base_info.pay_type_name }}</p>
      <p  v-if="base_info.status == 4"><span>付款时间：</span>{{ base_info.pay_time }}</p>
      <p  v-if="base_info.status == 4"><span>成交时间：</span>{{ base_info.finish_time }}</p>
    </div>
  </div>
</template>

<script>
import VueQr from "vue-qr";
export default {
  name: "lesson",
  components: {VueQr},
  data() {
    return {
      finished: 'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/orderFinished.jpg',
      base_info:{},
      goods_info: {},
      pageLoading: true,
      time:0,

      payUrl: "",
      dialogVisible: false,
    };
  },
  async mounted() {
    window.addEventListener("scroll", this.onLoad);
    this.getData();
  },
  destroyed() {
    window.removeEventListener("scroll", this.onLoad);
  },
  computed: {
    plat() {
      return this.$store.state.plat_id;
    },
  },
  methods: {
  
    async getData() {
      this.pageLoading = true;
     
        let res = await this.$request.orderDetail(this.$route.query.id)
        this.pageLoading = false;
        if(res.code == 0){
          this.base_info = res.data.base_info
          this.goods_info = res.data.goods_info[0]
          if(this.base_info.status == 1){
            this.time = new Date(this.base_info.created_at).getTime() + 10 * 60 * 1000 - new Date().getTime()
          }
        } else {
          this.$toast(res.msg);
        }
    },

    async toPay() {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      let res = await this.$request.orderPay(this.base_info.orderno);
      if (res.code == 0) {
        this.$toast.clear();
        // 限免商品
        
          this.dialogVisible = true;
          this.payUrl = JSON.parse(res.data.pay).code_url;
          this.isPay = true;
          this.payTimer = setTimeout(() => {
            this.getData();
          }, 1000);
      } else {
        this.$toast(res.msg);
      }
    },
   
  },
  beforeRouteUpdate(to, from, next) {
    console.log("beforeRouteUpdate");
    console.log(to);
    next();
  },
};
</script>

<style lang="scss" scoped>
:deep .payDialog {
  overflow: hidden;
  border-radius: 20px;
  text-align: center;
  .el-dialog__body {
    padding: 0;
  }
}
.toPay {
  padding-bottom: 30px;
  span {
    color: #666;
    display: block;
    padding-bottom: 20px;
  }
  img {
    width: 200px;
  }
  > p {
    cursor: pointer;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    padding: 10px 0;
    width: 200px;
    text-align: center;
    margin: 20px auto 0;
  }
}
.lesson {
  min-height: calc(100vh - 230px);
  overflow: hidden;
  font-family: "SourceHanSans";
  .title{
    font-size: 24px;
    color: #242424;
    font-weight: bold;
    border-bottom: 1px dashed #f0f0f0;
    padding-top: 30px;
    padding-bottom: 20px;
    width: 100%;
    margin-bottom: 25px;
  }
  .bg{
    background: #f9f9f9;
    border-radius: 6px;
    width: 100%;
  }
  .state{
    text-align: center;
    padding: 22px 0;
    p{
      color: #242424;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
    }
    .stated{
      color: #006bb3;
      font-size: 20px;
      margin-bottom: 10px;
      img{
        margin-right: 16px;
      }
    }
    .btn{
      width: 140px;
      height: 34px;
      border-radius: 17px;
      background: #0080d6;
      color: #fff;
      cursor: pointer;
      text-align: center;
      line-height: 34px;
      margin: 10px auto 0;
    }
  }
  h5{
    color: #242424;
    font-size: 16px;
    margin-top: 28px;
    margin-bottom: 10px;
  }
  .productInfo{
    display: flex;
    padding: 10px;
    color: #333;
    font-size: 20px;
    align-items: center;
    img{
      width: 110px;
      height: 62px;
      border-radius: 4.5px;
      margin-right: 18px;
      overflow: hidden;
      object-fit: cover;
    }
    >div{
      flex: 1;
      font-size: 14px;
      color: #7f7f7f;
      p{
        color: #333;
      }
    }
  }
  .orderInfo{
    padding: 18px;
    p{
      font-size: 14px;
      color: #666666;
      line-height: 40px;
      span{
        color: #333;
        display: inline-block;
        width: 100px;
      }
    }
  }
}
</style>

<style lang="scss">
body {
  background: #fff;
}
</style>
